<template>
  <v-select
    v-if="showFamilySelect"
    :model-value="navStore.activeFamily"
    label="Familie auswählen"
    :items="navStore.nav.families"
    item-title="name"
    item-value="id"
    return-object
    @update:model-value="updateFamily"
  />
  <!-- Ausgewählte oder einzige Familie -->
  <v-list v-if="navStore.activeFamily">
    <v-list-item
      class="bg-primary-darken-2"
      :to="`/family/${navStore.activeFamily.id}`"
    >
      {{ navStore.activeFamily.name }}
    </v-list-item>
    <v-list-item
      v-for="(item, i) in items"
      :key="i"
      :to="item.to"
      :prepend-icon="item.icon"
    >
      {{ item.text }}
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'NavFamily',
  data: () => ({
    showFamilySelect: false,
    items: null
  }),
  created () {
    if (this.navStore.familyCount > 1) {
      this.showFamilySelect = true
    }

    if (this.navStore.activeFamily) {
      this.items = this.getActiveFamilyItems()
    }
  },
  methods: {
    getActiveFamilyItems () {
      const items = [
        {
          text: 'Mitglieder verwalten',
          to:   `/family/${this.navStore.activeFamily.id}/members`,
          icon: 'mdi-account-multiple'
        },
        {
          text: 'Dokumentieren',
          to:   `/family/${this.navStore.activeFamily.id}/activities`,
          icon: 'mdi-file-document-edit'
        },
        {
          text: 'Lernen',
          to:   `/family/${this.navStore.activeFamily.id}/learn`,
          icon: 'mdi-school'
        },
        {
          text: 'Diskutieren',
          to: `/family/${this.navStore.activeFamily.id}/topics`,
          icon: 'mdi-forum'
        }/* ,
        {
          text: 'Medien verwalten',
          to: `/family/${this.navStore.activeFamily.id}/media` },
          icon: '' */
      ]
      return items
    },
    updateFamily (selectedFamily) {
      this.navStore.setActiveFamily(selectedFamily)
      this.items = this.getActiveFamilyItems()
      this.$router.push(`/family/${this.navStore.activeFamily.id}`)
    }
  }
}
</script>
