import axios from 'axios'
import { useAuthStore } from '@/stores/authStore.js'
import router from '@/router'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
})

instance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore()
    const jwt = authStore.user?.jwt
    if (jwt) {
      config.headers.Authorization = `Bearer ${jwt}`
    }
    /* Damit die Loginseite weiss, wohin sie weiterleiten muss */
    config.ref = router.currentRoute._value.path
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    const authStore = useAuthStore()
    const authHeader = response.headers.authorization
    if (authHeader) {
      const jwt = authHeader.split(' ')[1]
      authStore.updateJwt(jwt)
    }
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      /* Der "ref" Parameter wird bei jedem Request gesendet und enthält den Referer */
      router.push({ path: '/login', query: { ref: error.config.ref } })
    }
    if (error?.response?.status === 403) {
      router.push('/forbidden')
    }
    return Promise.reject(error)
  }
)

export default instance
