import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/authStore.js'
import { useNavStore } from '@/stores/navStore.js'

import Home from '@/views/HomePage.vue'
import Login from '@/views/LoginPage.vue'
import AccessDenied from '@/views/AccessDeniedPage.vue'
/* lazy-loaded */
const Onboarding = () => import(/* webpackChunkName: "onboarding" */ '@/views/OnboardingPage.vue')
const FamilyMembers = () => import(/* webpackChunkName: "familyMembers" */ '@/views/FamilyMembersPage.vue')
const FamilyActivities = () => import(/* webpackChunkName: "familyActivities" */ '@/views/FamilyActivitiesPage.vue')
const FamilyTopics = () => import(/* webpackChunkName: "familyTopics" */ '@/views/FamilyTopicsPage.vue')
const FamilyMessages = () => import(/* webpackChunkName: "familyMessages" */ '@/views/FamilyMessagesPage.vue')
const FamilyLearn = () => import(/* webpackChunkName: "familyLearn" */ '@/views/FamilyLearnPage.vue')
const LearnModule = () => import(/* webpackChunkName: "LearnModule" */ '@/views/LearnModulePage.vue')
const LearnModuleFinished = () => import(/* webpackChunkName: "LearnModuleFinished" */ '@/views/LearnModuleFinishedPage.vue')
const AdminModuleAll = () => import(/* webpackChunkName: "AdminModuleAll" */ '@/views/AdminModuleAllPage.vue')
const AdminModuleSave = () => import(/* webpackChunkName: "AdminModuleSave" */ '@/views/AdminModuleSavePage.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forbidden',
    name: 'AccessDenied',
    component: AccessDenied
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding
  },
  { /* Temporärer, damit keine Warnung in Konsole */
    path: '/family/:familyId',
    redirect: to => {
      return `/family/${to.params.familyId}/members`
    }
  },
  {
    path: '/family/:familyId/members',
    name: 'FamilyMembers',
    component: FamilyMembers
  },
  {
    path: '/family/:familyId/activities',
    name: 'FamilyActivities',
    component: FamilyActivities
  },
  {
    path: '/family/:familyId/learn',
    name: 'FamilyLearn',
    component: FamilyLearn
  },
  {
    path: '/family/:familyId/topics',
    name: 'FamilyTopics',
    component: FamilyTopics
  },
  {
    path: '/family/:familyId/topics/:topicId',
    name: 'FamilyMessages',
    component: FamilyMessages
  },
  { /* Temporärer, damit keine Warnung in Konsole */
    path: '/family/:familyId/media',
    redirect: '/'
  },
  {
    path: '/learn/module/:moduleId/finished',
    name: 'LearnModuleFinished',
    component: LearnModuleFinished
  },
  {
    path: '/learn/module/:moduleId',
    redirect: to => {
      return `/learn/module/${to.params.moduleId}/unit/1/step/1`
    }
  },
  {
    path: '/learn/module/:moduleId/unit/:unitOrder',
    redirect: to => {
      return `/learn/module/${to.params.moduleId}/unit/${to.params.unitOrder}/step/1`
    }
  },
  {
    path: '/learn/module/:moduleId/unit/:unitOrder/step/:stepOrder',
    name: 'LearnModuleUnitStep',
    component: LearnModule
  },
  {
    path: '/admin/create-module',
    name: 'AdminModuleCreate',
    component: AdminModuleSave
  },
  {
    path: '/admin/modules',
    name: 'AdminModuleAll',
    component: AdminModuleAll
  },
  {
    path: '/admin/module/id/:moduleId(\\d+)',
    name: 'AdminModuleEdit',
    component: AdminModuleSave
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  const authStore = useAuthStore()
  if (!authStore.isAuthed && to.name !== 'Login') {
    return {
      name: 'Login',
      query: {
        ref: to.path
      }
    }
  }

  if (authStore.isOnboarding && to.name !== 'Onboarding') {
    return '/onboarding'
  }

  if (to.path.startsWith('/admin/')) {
    const navStore = useNavStore()
    navStore.setActiveFamily(null)
  }
})

export default router
