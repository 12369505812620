// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import colors from 'vuetify/lib/util/colors'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: colors.blueGrey.base,
          'primary-darken-1': colors.blueGrey.darken1,
          'primary-darken-2': colors.blueGrey.darken2,
          'primary-darken-3': colors.blueGrey.darken3,
          'primary-darken-4': colors.blueGrey.darken4,
          'primary-lighten-1': colors.blueGrey.lighten1,
          'primary-lighten-2': colors.blueGrey.lighten2,
          'primary-lighten-3': colors.blueGrey.lighten3,
          'primary-lighten-4': colors.blueGrey.lighten4,
          'primary-lighten-5': colors.blueGrey.lighten5
        }
      }
    }
  }
})
