import http from '@/services'

export default {
  async getModule (id) {
    try {
      const response = await http.get(`learn.cfc?method=getModule&id=${id}`)
      return response.data.module
    } catch (error) {
      return null
    }
  },
  async updateProgress (progress) {
    try {
      await http.post('learn.cfc?method=updateProgress', progress)
      return true
    } catch (error) {
      return false
    }
  },
  async saveAnswer (question) {
    try {
      const response = await http.post('learn.cfc?method=saveAnswer', question)
      return response.data
    } catch (error) {
      return {
        error: true
      }
    }
  }
}
