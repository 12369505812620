import { defineStore } from 'pinia'
import authService from '@/services/authService.js'
import { useNavStore } from '@/stores/navStore.js'
import { useLearnStore } from '@/stores/learnStore.js'

const initialUser = JSON.parse(localStorage.getItem('user')) || null

export const useAuthStore = defineStore('auth', {
  state () {
    return {
      user: initialUser
    }
  },
  getters: {
    isAuthed: (state) => state.user !== null,
    /* "Normale" User haben IDs über 500 */
    isTopadmin: (state) => state.user && state.user.id < 100,
    perms: (state) => state.user?.perms,
    isOnboarding: (state) => state.user?.onboarding,
    fullName: (state) => `${state.user?.firstName} ${state.user?.lastName}`
  },
  actions: {
    async login (creds) {
      this.user = await authService.authUser(creds)
      localStorage.setItem('user', JSON.stringify(this.user))

      const navStore = useNavStore()
      navStore.createNav(this.perms)
    },
    logout () {
      this.user = null
      localStorage.removeItem('user')

      const navStore = useNavStore()
      navStore.removeNav()

      const learnStore = useLearnStore()
      learnStore.clearState()
    },
    updateJwt (newJwt) {
      this.user.jwt = newJwt
      localStorage.setItem('user', JSON.stringify(this.user))
    },
    updateName (name) {
      this.user.firstName = name.firstName
      this.user.lastName = name.lastName
      this.user.onboarding = false
    },
    getRoleIdInActiveFamily () {
      const navStore = useNavStore()
      const fid = navStore.activeFamily?.id || 0

      const permForFamily = this.perms.find(({ familyId }) => familyId === fid)
      return permForFamily?.roleId || 0 /* Topadmins haben keine roleIds in perms */
    },
    isAllowed (roles) {
      if (this.isTopadmin) {
        return true
      }

      let isAllowed = false
      for (const role of roles) {
        if (role === 'locAdmin' && this.isLocMatching()) {
          isAllowed = true
        } else if (this.isFamMatching(role)) {
          isAllowed = true
        }
      }

      return isAllowed
    },
    isLocMatching () {
      const perms = this.perms
      if (perms) {
        const navStore = useNavStore()
        const allowedRoles = perms.filter(perm => perm.roleName === 'locAdmin' && perm.locationId === navStore.activeLocation.id)
        if (allowedRoles.length) {
          return true
        }
      }

      return false
    },
    isFamMatching (role) {
      const perms = this.perms
      if (perms) {
        const navStore = useNavStore()
        const allowedRoles = perms.filter(perm => perm.roleName === role && perm.familyId === navStore.activeFamily.id)
        if (allowedRoles.length) {
          return true
        }
      }

      return false
    },
    isCoachOfFamily (familyId) {
      let fid = parseInt(familyId, 10)

      /* Ohne Parameter wird activeFamily verwendet */
      if (!fid) {
        const navStore = useNavStore()
        /* Wenn z. B. ein Lernmodule aus dem Adminbereich ausgerufen wird */
        if (!navStore.activeFamily) {
          return false
        }
        fid = navStore.activeFamily.id
      }
      const perms = this.perms
      if (perms) {
        const coachOfFamily = perms.filter(perm => perm.roleName === 'coach' && perm.familyId === fid)
        if (coachOfFamily.length) {
          return true
        }
      }

      return false
    }
  }
})
