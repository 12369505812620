<template>
  <ButtonResponsive
    icon="mdi-message-alert-outline"
    :on-click="toggleDialog"
  >
    Feedback geben
  </ButtonResponsive>

  <v-dialog
    v-model="isDialogOpen"
    persistent
    max-width="800"
  >
    <v-card
      title="Feedback geben"
    >
      <v-card-text>
        <v-form
          ref="form"
          class="feedback-form"
          @submit.prevent="sendFeedback"
        >
          <p class="mb-5">
            <strong>Vielen Dank</strong>, dass Sie mithelfen, die Onlineplattform der
            "Wirkstatt LQ" zu verbessern.
          </p>
          <p class="mb-5">
            Bitte wählen Sie eine passende Kategorie im Drop-Down-Menü
            und beschreiben Sie Ihr Anliegen möglichst genau.
          </p>

          <v-select
            v-model="feedback.typeId"
            :items="types"
            label="Bitte Kategorie auswählen:"
            :rules="[v => !!v || 'Bitte auswählen']"
          />

          <v-textarea
            v-model.trim="feedback.content"
            label="Beschreibung"
            :rules="[v => !!v || 'Bitte ausfüllen']"
            variant="outlined"
            auto-grow
          />

          <v-alert
            v-if="isAlertVisible"
            type="error"
            class="mb-3"
            variant="tonal"
          >
            <v-alert-title>
              Speichern fehlgeschlagen!
            </v-alert-title>
            Bitte versuchen Sie es noch einmal.
            (Irgendwie ironisch, wenn man Feedback geben will &#x1f641;)
          </v-alert>

          <div class="d-flex flex-column-reverse flex-sm-row">
            <v-btn
              prepend-icon="mdi-close"
              variant="elevated"
              @click="closeAndResetDialog"
            >
              Abbrechen
            </v-btn>

            <v-spacer />

            <v-btn
              type="submit"
              prepend-icon="mdi-send"
              color="primary-lighten-5"
              variant="elevated"
              :loading="isLoading"
              :disabled="isLoading"
              class="mb-3 mb-sm-0"
            >
              Feedback senden
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import miscService from '@/services/miscService'
import ButtonResponsive from '@/components/common/ButtonResponsive'

export default {
  name: 'FeedbackDialog',
  components: {
    ButtonResponsive
  },
  data: () => ({
    feedback: {
      /* Muss für Validierung gesetzt sein */
      typeId: null
    },
    types: [],
    isDialogOpen: false,
    isLoading: false,
    isAlertVisible: false
  }),
  async created () {
    this.types = await miscService.getFeedbackTypes()
  },
  methods: {
    async sendFeedback () {
      if ((await this.$refs.form.validate()).valid) {
        this.isLoading = true
        const isSuccessful = await miscService.sendFeedback(this.feedback)
        this.isLoading = false
        if (isSuccessful) {
          this.closeAndResetDialog()
        } else {
          this.isAlertVisible = true
        }
      }
    },
    toggleDialog () {
      this.isDialogOpen = !this.isDialogOpen
    },
    closeAndResetDialog () {
      this.feedback = {
        typeId: null
      }
      this.isAlertVisible = false
      this.isDialogOpen = false
    }
  }
}
</script>
