<template>
  <v-btn
    v-if="$vuetify.display.xs"
    :icon="icon"
    @click="onClick"
  />
  <v-btn
    v-else
    :prepend-icon="icon"
    @click="onClick"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'ButtonResponsive',
  components: {

  },
  props: {
    onClick: {
      type: Function,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
