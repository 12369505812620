import http from '@/services'

export default {
  async authUser (creds) {
    try {
      const response = await http.post('auth.cfc?method=authUser', creds)
      return response.data
    } catch (error) {
      return null
    }
  }
}
