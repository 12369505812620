<template>
  <v-container>
    <v-card
      width="500px"
      max-width="100%"
      class="mx-auto"
    >
      <v-card-title class="bg-primary">
        <span class="text-white">Lernwerkstatt für pflegende Angehörige</span>
      </v-card-title>
      <v-card-subtitle class="bg-primary">
        <span class="text-white">
          Bitte melden Sie sich an:
        </span>
      </v-card-subtitle>
      <v-card-text>
        <v-form
          ref="form"
          @submit.prevent="login"
        >
          <v-text-field
            v-model.trim="creds.username"
            label="E-Mail-Adresse"
            type="email"
            prepend-icon="mdi-account"
            :rules="[v => !!v || 'Bitte ausfüllen']"
            autofocus
          />
          <v-text-field
            v-model.trim="creds.password"
            label="Passwort"
            prepend-icon="mdi-lock"
            :append-inner-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            :rules="[v => !!v || 'Bitte ausfüllen']"
            @click:append-inner="showPass = !showPass"
          />
          <div class="text-right">
            <v-btn
              type="submit"
              prepend-icon="mdi-login"
              :loading="loading"
              :disabled="loading"
            >
              Anmelden
            </v-btn>
          </div>
          <v-alert
            v-if="isAlertVisible"
            type="error"
            variant="tonal"
            density="compact"
            class="mt-3"
          >
            <v-alert-title>
              Anmeldung fehlgeschlagen!
            </v-alert-title>
            Bitte versuchen Sie es noch einmal.
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'LoginPage',
  data: () => ({
    creds: {},
    showPass: false,
    loading: false,
    isAlertVisible: false
  }),
  created () {
    this.authStore.logout()
    this.navStore.updateTabTitle('')
  },
  methods: {
    async login () {
      if (this.$refs.form.validate()) {
        this.loading = true
        await this.authStore.login(this.creds)
        this.loading = false
        if (this.authStore.isAuthed) {
          const ref = this.$route.query.ref
          this.$router.push(ref || '/')
        } else {
          this.isAlertVisible = true
        }
      }
    }
  }
}
</script>
