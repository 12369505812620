import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import { createPinia } from 'pinia'
import { useNavStore } from '@/stores/navStore.js'
import { useAuthStore } from '@/stores/authStore.js'

loadFonts()

const app = createApp(App).use(router).use(vuetify).use(createPinia())
/* Beide Stores werden schon in "App.vue" benötigt */
app.config.globalProperties.navStore = useNavStore()
app.config.globalProperties.authStore = useAuthStore()
app.mount('#app')
