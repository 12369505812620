<template>
  <v-alert
    type="error"
    title="Zugriff verweigert"
    variant="tonal"
  >
    Ihnen fehlt die nötige Berechtigung, um diese Seite anzuzeigen.
  </v-alert>
</template>

<script>
export default {
  name: 'AccessDeniedPage',
  created () {
    this.navStore.updateTabTitle('')
  }
}
</script>
