import { defineStore } from 'pinia'
import { useAuthStore } from '@/stores/authStore.js'
import learnService from '@/services/learnService'

export const useLearnStore = defineStore('learn', {
  state () {
    return {
      activeModule: null,
      currentPosition: null
    }
  },
  getters: {
  },
  actions: {
    async getStep (routeParams) {
      const params = this.convertToInts(routeParams)

      if (this.activeModule?.id !== params.moduleId) {
        await this.getModule(params.moduleId)
      }

      if (this.activeModule === null || !this.activeModule.units.length || this.isStepBlocked(params.unitOrder, params.stepOrder)) {
        return null
      } else {
        this.updateCurrentPosition(params.unitOrder, params.stepOrder)

        const progUnit = this.activeModule.progress.unitOrder
        const progStep = this.activeModule.progress.stepOrder
        const normalizedUnitOrder = this.currentPosition.unitOrder
        const normalizedStepOrder = this.currentPosition.stepOrder
        if (
          /* Nur aktualisiert, wenn nicht abgeschlossen und eins weiter als bisheriger Progress */
          !this.activeModule.progress.isFinished && (
            (normalizedUnitOrder === progUnit && normalizedStepOrder === progStep + 1) ||
            (normalizedUnitOrder === progUnit + 1 && normalizedStepOrder === 1)
          )
        ) {
          await this.updateProgress(normalizedUnitOrder, normalizedStepOrder, 0)
        }

        return this.extractStep(normalizedUnitOrder, normalizedStepOrder)
      }
    },
    /* Es ist nur erlaubt, einen Schritt vorwärts zu machen oder
       beliebige vorherige Schritte zu besuchen. (in Bezug auf "progress") */
    isStepBlocked (unitOrder, stepOrder) {
      const progUnit = this.activeModule.progress.unitOrder
      const progStep = this.activeModule.progress.stepOrder

      /* Module abgeschlossen */
      if (this.activeModule.progress.isFinished) {
        return false
      }
      /* Frühere Blöcke */
      if (unitOrder < progUnit) {
        return false
      }
      /* Maximale einen Schritt voraus im gleichen Block */
      if (unitOrder === progUnit && stepOrder <= progStep + 1) {
        return false
      }
      /* Letzter Schritt zu erstem Schritt im nächsten Block */
      const lastStepOfUnit = this.activeModule.units[progUnit - 1].steps.length
      if (unitOrder === progUnit + 1 && progStep === lastStepOfUnit && stepOrder === 1) {
        return false
      }

      return true
    },
    getProgressDialogData () {
      const prog = this.activeModule.progress

      /* Nur Daten senden, wenn
         - das Module nicht abgeschlossen ist
         - der "progress" nicht auf der ersten Seite ist
         - wir nicht bereits auf dieser Seite sind */
      if (
        prog.isFinished ||
        (prog.unitOrder === 1 && prog.stepOrder === 1) ||
        (prog.unitOrder === this.currentPosition.unitOrder && prog.stepOrder === this.currentPosition.stepOrder)
      ) {
        return null
      } else {
        return {
          moduleId:  this.activeModule.id,
          unitOrder: prog.unitOrder,
          unitName:  this.activeModule.units[prog.unitOrder - 1].name,
          stepOrder: prog.stepOrder,
          stepName:  this.activeModule.units[prog.unitOrder - 1].steps[prog.stepOrder - 1].name,
          date:      new Date(parseInt(prog.changedAt, 10))
        }
      }
    },
    async saveAnswer (question) {
      const response = await learnService.saveAnswer(question)
      if (response.error) {
        return false
      } else {
        this.updateReviews(question.id, response.sanitizedAnswer)
        return true
      }
    },
    async finishModule () {
      if (this.activeModule) {
        await this.updateProgress(1, 1, 1)
      }
    },
    clearState () {
      /* Bei Logout, falls sich gleich jemand anders einloggt */
      this.activeModule = null
      this.currentPosition = null
    },

    /* Interne Funktionen */

    convertToInts (routeParams) {
      return {
        moduleId: parseInt(routeParams.moduleId, 10),
        unitOrder: parseInt(routeParams.unitOrder, 10),
        stepOrder: parseInt(routeParams.stepOrder, 10)
      }
    },
    async getModule (id) {
      this.activeModule = await learnService.getModule(id)

      /* Für Coaches und Admins wird die Lerneinheit automatisch auf abgeschlossen gesetzt,
      damit sie uneingeschränkt navigieren können */
      const authStore = useAuthStore()
      /* Ohne Parameter wird die activeFamily verwendet */
      if (this.activeModule.units.length && (authStore.isCoachOfFamily() || authStore.isTopadmin)) {
        this.finishModule()
      }
    },
    /* Units und Steps sind von Eins her durchnummeriert,
       um eine konsistente URL zu haben und im Router als
       Default die Eins angeben zu können */
    updateCurrentPosition (unitOrder, stepOrder) {
      if (this.activeModule === null) {
        this.currentPosition = null
      } else {
        const curPos = {
          moduleId:     this.activeModule.id,
          moduleName:   this.activeModule.name,
          unitMaxOrder: this.activeModule.units.length
        }

        curPos.unitOrder    = this.clamp(unitOrder, 1, curPos.unitMaxOrder)
        const curUnit       = this.activeModule.units[curPos.unitOrder - 1]
        curPos.unitName     = curUnit.name
        curPos.unitType     = curUnit.typeName + curUnit.typeShort ? ` (${curUnit.typeShort})` : ''
        curPos.stepMaxOrder = curUnit.steps.length
        curPos.stepOrder    = this.clamp(stepOrder, 1, curPos.stepMaxOrder)
        const curStep       = curUnit.steps[curPos.stepOrder - 1]
        /* curPos.stepName     = `${curStep.name} (${curStep.typeShort})` */
        curPos.stepName     = curStep.name

        if (curPos.unitOrder === 1 && curPos.stepOrder === 1) {
          curPos.previous = null
        } else {
          curPos.previous = {
            stepOrder: curPos.stepOrder === 1 ? this.activeModule.units[curPos.unitOrder - 2].steps.length : curPos.stepOrder - 1,
            unitOrder: curPos.stepOrder === 1 ? curPos.unitOrder - 1 : curPos.unitOrder
          }
          curPos.previous.stepName = this.activeModule.units[curPos.previous.unitOrder - 1].steps[curPos.previous.stepOrder - 1].name
        }

        if (curPos.unitOrder === curPos.unitMaxOrder && curPos.stepOrder === curPos.stepMaxOrder) {
          curPos.next = null
        } else {
          curPos.next = {
            stepOrder: curPos.stepOrder === curPos.stepMaxOrder ? 1 : curPos.stepOrder + 1,
            unitOrder: curPos.stepOrder === curPos.stepMaxOrder ? curPos.unitOrder + 1 : curPos.unitOrder
          }
          curPos.next.stepName = this.activeModule.units[curPos.next.unitOrder - 1].steps[curPos.next.stepOrder - 1].name
        }

        curPos.totalSteps = 0
        curPos.currentSteps = curPos.stepOrder

        this.activeModule.units.forEach((unit, i) => {
          curPos.totalSteps += unit.steps.length
          if (i < curPos.unitOrder - 1) {
            curPos.currentSteps += unit.steps.length
          }
        })

        this.currentPosition = curPos
      }
    },
    clamp (num, min, max) {
      return Math.max(Math.min(max, num), min)
    },
    async updateProgress (unitOrder, stepOrder, isFinished) {
      this.activeModule.progress = {
        unitOrder: unitOrder,
        stepOrder: stepOrder,
        isFinished: isFinished,
        changedAt: Date.now()
      }

      const progress = {
        moduleId:   this.activeModule.id,
        unitOrder:  unitOrder,
        stepOrder:  stepOrder,
        isFinished: isFinished
      }

      await learnService.updateProgress(progress)
    },
    extractStep (unitOrder, stepOrder) {
      return this.activeModule?.units[unitOrder - 1]?.steps[stepOrder - 1] || null
    },
    updateReviews (qid, answer) {
      if (this.activeModule) {
        this.activeModule.units.forEach((unit, i) => {
          unit.steps.forEach((step, j) => {
            step.items.forEach((item, k) => {
              if (item.type === 'LearnItemReview') {
                item.review.forEach((question, l) => {
                  if (question.id === qid) {
                    this.activeModule.units[i].steps[j].items[k].review[l].answer = answer
                  }
                })
              }
            })
          })
        })
      }
    }
  }
})
