import http from '@/services'

export default {
  async getFeedbackTypes () {
    try {
      const response = await http.get('misc.cfc?method=getFeedbackTypes')
      return response.data.feedbackTypes
    } catch (error) {
      return null
    }
  },
  async sendFeedback (feedback) {
    try {
      await http.post('misc.cfc?method=sendFeedback', feedback)
      return true
    } catch (error) {
      return false
    }
  }
}
