<template>
  <v-list>
    <v-list-item class="bg-primary-darken-2">
      Admin
    </v-list-item>
    <v-list-item
      to="/admin/modules"
      prepend-icon="mdi-view-module"
    >
      Lernmodule
    </v-list-item>
  </v-list>
</template>
