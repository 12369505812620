<template>
  <v-container fluid>
    <!-- <pre>{{ JSON.stringify(authStore.user, null, 2) }}</pre> -->
  </v-container>
</template>

<script>
export default {
  name: 'HomePage',
  created () {
    this.navStore.updateTabTitle('')
  }
}
</script>
