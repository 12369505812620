import { defineStore } from 'pinia'
import { useAuthStore } from '@/stores/authStore.js'

const initialFamily = JSON.parse(localStorage.getItem('activeFamily')) || null

export const useNavStore = defineStore('nav', {
  state () {
    return {
      nav: null,
      activeFamily: null,
      activeLocation: null,
      activePage: ''
    }
  },
  getters: {
    toolbarTitle: (state) => {
      let title = ''
      if (state.activeFamily) {
        title += state.activeFamily.name
      } else if (state.activeLocation) {
        title += state.activeLocation.name
      } else if (state.nav) {
        /* Ohne Family und Location, aber trotzdem Seitennav (z. B. Login hat keine Seitennav)  */
        title += 'Adminbereich'
      }

      if (state.activePage) {
        title += ` - ${state.activePage}`
      }

      return title
    },
    familyCount: (state) => state.nav?.families?.length || 0
  },
  actions: {
    /* Entweder nach dem Login aufgerufen mit Perms oder
       bei Programmstart von App.vue ohne Perms (evtl. User bereits in LocalStorage) */
    createNav (permsArg) {
      this.activeFamily = initialFamily

      const authStore = useAuthStore()
      const perms = permsArg || authStore.perms

      if (!perms) {
        this.nav = null
      } else {
        const nav = {}

        /* Familien */
        nav.families = []
        for (const perm of perms) {
          if (perm.familyId) {
            nav.families.push({
              id: perm.familyId,
              name: perm.familyName
            })
          }
        }

        if (nav.families.length === 1) {
          this.setActiveFamily(nav.families[0])
        }

        this.nav = nav
      }
    },
    removeNav () {
      this.nav = null
      this.activeFamily = null
    },
    setActiveFamily (family) {
      this.activeFamily = family
      localStorage.setItem('activeFamily', JSON.stringify(this.activeFamily))
    },
    updateTabTitle (pageTitle) {
      this.activePage = pageTitle
      /* Kann nicht in toolbarTitle direkt gesetzt werden, da z. B. beim Wechsel
         von Admin zu Familie die activeFamily ändert bevor der Seitenwechsel passiert.
         Dadurch würde sonst in der Browser History z. B. "Familie Koch - Lernmodul bearbeiten"
         stehen anstelle von "Adminbereich - Lernmodul bearbeiten" */
      document.title = this.toolbarTitle || 'Wirkstatt LQ'
    }
  }
})
