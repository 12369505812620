<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list>
        <v-list-item
          class="bg-primary"
          to="/"
        >
          <h2 class="text-white">
            Die Lernwerkstatt für pflegende Angehörige
          </h2>
        </v-list-item>
      </v-list>

      <nav-family v-if="navStore.nav" />
      <nav-admin v-if="authStore.isTopadmin" />
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon
        v-if="authStore.isAuthed"
        :title="drawer ? 'Menü verbergen' : ' Menü anzeigen'"
        @click="drawer = !drawer"
      />

      <v-toolbar-title>
        {{ navStore.toolbarTitle }}
      </v-toolbar-title>

      <!-- Ein Div um alles herum geht nicht, da die Divider dann nicht funktionieren -->
      <v-divider
        v-if="authStore.isAuthed && $vuetify.display.smAndUp"
        vertical
      />

      <FeedbackDialog v-if="authStore.isAuthed" />

      <v-divider
        v-if="authStore.isAuthed && $vuetify.display.smAndUp"
        vertical
      />

      <ButtonResponsive
        v-if="authStore.isAuthed"
        icon="mdi-logout"
        :on-click="logout"
      >
        Abmelden
      </ButtonResponsive>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavFamily from '@/components/nav/NavFamily'
import NavAdmin from '@/components/nav/NavAdmin'
import FeedbackDialog from '@/components/FeedbackDialog'
import ButtonResponsive from '@/components/common/ButtonResponsive'

export default {
  name: 'App',
  components: {
    NavFamily,
    NavAdmin,
    FeedbackDialog,
    ButtonResponsive
  },
  data: () => ({
    drawer: null
  }),
  created () {
    this.navStore.createNav()
  },
  methods: {
    logout () {
      this.$router.push('/login')
    }
  }
}
</script>

<style>
/* nicht-fluide Container sind zu breit für diese Seite */
.v-container.small-container {
  max-width: 900px;
}

/* Margins sind standardmässig 0 */
p {
  margin-bottom: 1rem;
}

/* dark-tooltip ist eine eigene Klasse, die auf Tooltips angewendet werden kann */
.v-tooltip.dark-tooltip .v-overlay__content {
  background: black;
}

/* Drop-Down in TinyMce (z. B. formatselect) werden innerhalb von v-dialogs
   im Hintergrund geöffnet. Zusätzlich müssen auf v-dialog
   :retain-focus="false"
   :no-click-animation="true"
   angewendet werden, damit nicht alles "rumzuckt" */
.tox-tinymce-aux {
  z-index: 9999 !important;
}
</style>
